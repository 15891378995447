import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import { BasicContainer } from 'components/content-blocks/basic-container';
import CustomerFeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import FeaturesGrid from 'components/content-blocks/features-grid';
import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';

import './styles/payroll-software.scss';
import '../../components/content-blocks/styles/media-object.scss';


const PayrollSoftware = () => {
  const data = useStaticQuery(graphql`
    {
        allWordpressPage(filter: { slug: { eq: "full-service-payroll-software" } }) {
          edges {
            node {
              wordpress_id
              yoast_head_json {
                title
                description
                canonical
                og_title
                og_description
                og_image {
                  url {
                    source_url
                  }
                }
                og_url
                og_type
                twitter_title
                twitter_description
                twitter_image {
                  source_url
                }
              }
              acf {
                hero {
                  title
                  sub_title
                  copy
                  schedule_demo {
                    button_text
                    button_url
                  }
                  background_desktop_image {
                    source_url
                  }
                  background_mobile_image {
                    source_url
                  }
                }
                customer_feature_benefit {
                  sub_title
                  title
                  copy
                  customer {
                    business
                    name
                    photo_desktop {
                      source_url
                    }
                    photo_mobile {
                      source_url
                    }
                    since
                  }
                  stats {
                    value_1
                    descriptor_1
                    value_2
                    descriptor_2
                    value_3
                    descriptor_3
                  }
                }
                features_grid {
                  title
                  sub_title
                  buttons {
                    title
                    body
                    alt_text
                    icon_width
                    icon_from_library
                    mobile_screenshot {
                      source_url
                    }
                    desktop_screenshot {
                      source_url
                    }
                  }
                }
                media_object {
                  title
                  body
                  desktop_image {
                    source_url
                  }
                  mobile_image {
                    source_url
                  }
                  wysiwyg
                  custom_calendar_button {
                    button_url
                    button_text
                  }
                }
                basic_title_subtitle_copy_image_2 {
                  title
                  copy
                  demo_url
                }
                cards_1 {
                  header {
                    title
                    sub_title
                  }
                  card {
                    header
                    image {
                      source_url
                    }
                    title
                    copy
                    link_url
                    link_text
                    is_active
                  }
                }
                cards_2 {
                  header {
                    title
                    copy
                  }
                  card {
                    header
                    title
                  }
                }
                features_grid {
                  title
                  sub_title
                  buttons {
                    title
                    body
                    alt_text
                    icon_width
                    icon_from_library
                    mobile_screenshot {
                      source_url
                    }
                    desktop_screenshot {
                      source_url
                    }
                  }
                }
                faq {
                  question
                  answer
                }
              }
            }
          }
        }
      }
      
`);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContext.hero;
  const cardsData1 = pageContext.cards_1;
  const mediaObject1 = pageContext.media_object[0];
  const mediaObject2 = pageContext.media_object[1];
  const mediaObject3 = pageContext.media_object[2];
  const mediaObject4 = pageContext.media_object[3];
  const featuresGrid = pageContext.features_grid;
  const cardsData2 = pageContext.cards_2;
  const payrollUs = pageContext.basic_title_subtitle_copy_image_2;
  const faq = pageContext.faq;
  const customerFeatureBenefitQuote = pageContext.customer_feature_benefit;

  return (
    <Layout
      faq={ faq }
      customHubspotMeetingUrl={ pageContext.hero.schedule_demo.button_url }
      showInitFields={ false }
      footerSignupButtonText="Schedule Demo"
      footerCtaTitle="Ready to see When I Work in action?"
    >
      <Yoast { ...yoast } />
      <div className="payroll-software-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } />
        <HighlightCards { ...cardsData1 } className="marketing-cards" hasImage hasTitle titleAsHeader />

        <MediaObject { ...mediaObject1 } className="reverse" />

        <MediaObject { ...mediaObject2 } />

        <MediaObject { ...mediaObject4 } className="reverse more-padding" />

        <CustomerFeatureBenefit { ...customerFeatureBenefitQuote[0] } quote className="customer-feature-benefit-quotes" />

        <MediaObject { ... mediaObject3 } className="reverse" />
  
        <FeaturesGrid { ...featuresGrid[0] } className="reverse" />

        <HighlightCards
          { ...cardsData2 }
          hasCopy
          hasTitle
          className="payroll-features"
        />
        <BasicContainer { ...payrollUs } hideMobile />
      </div>
    </Layout>
  );   
};

export default PayrollSoftware;